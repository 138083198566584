.routeCardContainer {
    height: auto;
}

.routeCard {
    margin: 0.75vw;
    border-radius: 5%;
    width: 10vw;
    display: flex;
    flex-direction: column;
}

.imgRoute {
    width: 100%;
    height: 21.379772961816304vh;
}

.imgRoute:hover {
    cursor: pointer;
}

.routeCard:hover .deleteIconCol {
    visibility: visible;
}

.deleteIconCol:hover {
    cursor: pointer;
}