.title {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 5vh;
    align-items: center;
}

.titleMini {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 1vw;
    align-items: center;
}

.icon {
    display: flex;
    flex-direction: column;
    margin-left: 5%;
    margin-right: 0.5vw;
    width: 2.7416666666666665vw;
}

.iconMini {
    display: flex;
    flex-direction: column;
    margin-left: 10%;
    margin-right: 0.5vw;
    width: 2.5416666666666665vw;
}

.titleStyle {
    opacity: 1;
    padding: 5px;
    fill: #696969;
    font-family: red-hat-display-bold;
    font-size: 2.34375vw;
    fill-rule: evenodd;
    font-weight: bold;
    color: #03464b;
    margin-bottom: 0 !important;
}

.titleStyleDark {
    opacity: 1;
    padding: 5px;
    fill: #696969;
    font-family: red-hat-display-bold;
    font-size: 2.34375vw;
    fill-rule: evenodd;
    font-weight: bold;
    color: #03464b;
    margin-bottom: 0 !important;
}

.titleStyleDarkMini {
    opacity: 1;
    padding: 2.5px;
    fill: #696969;
    font-family: red-hat-display-bold;
    font-size: 1.34375vw;
    fill-rule: evenodd;
    font-weight: bold;
    color: #03464b;
    margin-bottom: 0 !important;
}

.titleStyleLight {
    opacity: 1;
    padding: 5px;
    fill: #696969;
    font-family: red-hat-display-bold;
    font-size: 2.34375vw;
    fill-rule: evenodd;
    font-weight: bold;
    color: #1C8693;
    margin-bottom: 0 !important;
}

.titleStyleLightMini {
    opacity: 1;
    padding: 2.5px;
    fill: #696969;
    font-family: red-hat-display-bold;
    font-size: 1.34375vw;
    fill-rule: evenodd;
    font-weight: bold;
    color: #1C8693;
    margin-bottom: 0 !important;
}


.filterTitle {
    display: flex;
    flex-direction: row;
    font-family: mulish-regular;
    color: #1C8693;
    font-size: 18px;
    margin-top: 2.6525198938992043vh;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    margin-left: 12.5%;
}

.blueLineLeft {
    display: flex;
    flex-direction: row;
    width: 13.333333333333334vw;
    height: 0.052083333333333336vw;
    background-image: url("../../../assets/styles/images/home/filter/BLUE\ LINE\ LEFT.png");
    background-size: cover;
}

.blueLineRight {
    display: flex;
    flex-direction: row;
    width: 13.489583333333334vw;
    height: 0.052083333333333336vw;
    background-image: url("../../../assets/styles/images/home/filter/BLUE\ LINE\ RIGHT.png");
    background-size: cover;
}