.wrapRow{
    display: flex;
    flex-direction: row;
    margin-top: 3vh;
    margin-left: 5%;
    height: auto;
    flex-wrap: wrap;
}

.plusLink{
    display: flex;
    flex-direction: column;
    font-size: xx-large;
}

.plusLinkDesign{
    font-size: xx-large;
    color: #1C8693;
    font-family: mulish-light;
    text-decoration: none;
    margin-top: 16.679772961816304vh;
}