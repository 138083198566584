.filteredRoutes{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    height: auto;
    justify-content: center;
    margin-top: 3.305039787798409vh;
    padding-left: 5.6041666666666665vw;
    padding-right: 5.6041666666666665vw;
}