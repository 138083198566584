.clientName{
    font-weight: bold;
    font-size: large;
    font-family: mulish-light;
    color: #696969;
    padding-left: 5px;
}

.mapSection{
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    justify-content: center;
    height: 60.59958720330237vh;
    padding-left: 2%;
    padding-right: 2%;
}

.map{
    width: 100%;
    height: 100%;            
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
}