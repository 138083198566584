.contactSection {
    display: flex;
    flex-direction: row;
    width: 70%;
    height: 9.018567639257295vh;
    border-radius: 10px;
    border-color: rgba(180, 126, 56, 0.5);
    border-style: solid;
    border-width: thin;
    margin-left: 2.5em;
    margin-top: 1em;
}

.addressCol {
    display: flex;
    flex-direction: column;
    width: 30%;
    justify-content: center;
}

.contactTitle {
    display: flex;
    flex-direction: row;
    font-family: mulish-light;
    font-size: 1.5915119363395225vh;
    margin-left: 0.9765625vw;
}

.contactContent {
    display: flex;
    flex-direction: row;
    font-family: mulish-extrabold;
    font-size: 1.5915119363395225vh;
    margin-left: 0.9765625vw;
}

.phoneCol {
    display: flex;
    flex-direction: column;
    width: 25%;
    justify-content: center;
}

.emailCol {
    display: flex;
    flex-direction: column;
    width: 25%;
    justify-content: center;
}

.socialMediaCol {
    display: flex;
    flex-direction: column;
    width: auto;
    margin-left: 0.3vw;
    justify-content: center;
}

.row {
    display: flex;
    flex-direction: row;
}

.btn:hover {
    cursor: pointer;
    opacity: 0.8;
}

.instagramBtn {
    background-image: url("../../../../assets/styles/icons/instagram_background.jpg");
    background-size: cover;
    border-radius: 10px;
}

.instagramBtn:hover {
    cursor: pointer;
    opacity: 0.8;
}