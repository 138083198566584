.clientsPage {
  flex-direction: column;
}

.sectionClientsNews {
  display: flex;
  flex-direction: row;
  height: auto;
  width: 100%;
}

.clientsSection {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 75%;
  align-items: center;
}

.clients {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  height: auto;
  column-gap: 3%;
  justify-content: center;
  margin-top: 3.805039787798409vh;
}

.sectionNews {
  display: flex;
  flex-direction: column;
}

.titlePagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  width: 100%;
}
