.rowWithHeight {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 10vw;
    margin-top: 1vw;
}

.categoryContainer {
    display: flex;
    width: 5.5vw;
    align-items: center;
    justify-content: flex-start;
}

.categoryTag {
    display: flex;
    flex-direction: column;
    width: 5.25vw;
    border-radius: 0.5vw;
    background-color: #ECECEC;
    justify-content: center;
    align-items: center;
}

.category {
    font-family: mulish-regular;
    padding: 0.15vw;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0 !important;
    font-size: 0.8vw;
}

.rateContainer {
    display: flex;
    flex-direction: row;
    width: 3vw;
    align-items: center;
    justify-content: center;
}

.rateTag {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #fbdc90;
    padding: 0.2vw;
    border-radius: 0.5vw;
    height: 2.838390092879257vh;
}

.row {
    display: flex;
    flex-direction: row;
}

.starCol {
    display: flex;
    flex-direction: column;
    width: 0.9114583333333334vw;
    height: 0.9114583333333334vw;
    align-self: center;
}

.rate {
    font-family: mulish-light;
    font-weight: bold;
    color: #696969;
    font-size: 0.8vw;
    margin-left: 0.1vw;
    margin-top: 0.1vh;
}

.fbShareContainer {
    width: 1.5vw;
}