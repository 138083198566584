.descriptionLogoSection{
    width: 100%;
    display: flex;
    flex-direction: row;
}

.descriptionContainer{
    width: 70%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.clientDescription{
    color: #696969 !important;
    font-family: mulish-light !important;
    font-size: 1.321666666666666vw !important;
}

.toastMessage{
    font-family: mulish-regular;
    font-size: 2.063983488132095vh;
    justify-content: center;
    align-items: center;
}