.nav {
    display: flex;
    justify-content: center;
    width: 100%;
    background-size: cover;
    align-items: flex-start;
}

.navColor {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 12vh !important;
    background-color: white;
    align-items: flex-start;
    position: fixed;
    z-index: 100000;
    top: 0;
    border-top: 2.3545706371191137vh solid #1A757F;
}

.logoBox {
    display: flex;
    flex-direction: row;
    background-image: url("../../../assets/styles/images/home/header/LOGO\ BOX\ BG.png");
    background-size: cover;
    height: 7.135416666666667vw;
    width: 6.145833333333333vw;
    align-items: center;
    justify-content: center;
    z-index: 1000000000;
}

.logo {
    display: flex;
    flex-direction: row;
    background-image: url("../../../assets/styles/images/home/header/LOGO_EP_NEGATIV.png");
    background-size: contain;
    height: 4.6875vw;
    width: 3.90625vw;
}

.menu {
    display: flex;
    flex-direction: row;
    text-decoration: none;
    /* Use flex boxes to align li items */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10vh;
    /* Remove default padding from major browsers */
    padding: 0;
    /* Hide the default decorations for li items */
    list-style: none !important;
    margin: 0 !important;
}

.menuItem {
    /* Display the elements in one line */
    display: flex;
    align-items: center;
    width: 10vw;
    justify-content: center;
}

.menuItem2 {
    /* Display the elements in one line */
    display: flex;
    align-items: center;
    width: 7vw;
    justify-content: center;
}

.loginContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 12vw;
}

.btnLogin {
    background-color: transparent;
    width: 4.479166666666667vw;
    height: 1.6666666666666667vw;
    border-radius: 0.3vw;
    border: 0.15vw solid #1c8693;
    align-items: center;
    justify-content: center;
}

.txtLogin {
    color: #1c8693;
    font-size: 0.8333333333333334vw;
    font-family: mulish-light;
    font-weight: bold;
    margin-bottom: 0 !important;
}

.langMenuItem {
    display: flex;
    align-items: center;
}

.flagSR {
    display: flex;
    flex-direction: row;
    background-image: url("../../../assets/styles/images/home/header/ZASTAVA\ SR.png");
    background-size: cover;
    width: 1.81671875vw;
    height: 1.81671875vw;
    margin-right: 0.5vw;
    filter: saturate(0);
}

.flagSR:hover {
    filter: saturate(1);
    cursor: pointer;
}

.flagHR {
    display: flex;
    flex-direction: row;
    background-image: url("../../../assets/styles/images/home/header/ZASTAVA\ HR.png");
    background-size: cover;
    width: 1.81671875vw;
    height: 1.81671875vw;
    margin-right: 0.5vw;
    filter: saturate(0);
}

.flagHR:hover {
    filter: saturate(1);
    cursor: pointer;
}

.flagEN {
    display: flex;
    flex-direction: row;
    background-image: url("../../../assets/styles/images/home/header/ZASTAVA\ EN.png");
    background-size: cover;
    width: 1.81671875vw;
    height: 1.81671875vw;
    margin-right: 0.5vw;
    filter: saturate(0);
}

.flagEN:hover {
    filter: saturate(1);
    cursor: pointer;
}

.accountMenuItem {
    display: flex;
    align-items: center;
    max-width: 12vw;
    justify-content: center;
}

.firstSection {
    display: flex;
    flex-direction: row;
    width: 60%;
}

.secondSection {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 40%;
}

.navLink {
    text-decoration: none;
    margin: 0 !important;
    display: flex;
    justify-content: center;
    color: #828282 !important;
    font-family: mulish-light !important;
    font-size: 0.9375vw !important;
    font-weight: bold;
}

.navLinkActive {
    text-decoration: none;
    margin: 0 !important;
    display: flex;
    justify-content: center;
    color: #eaa73a !important;
    font-family: mulish-light !important;
    font-size: 0.9375vw !important;
    font-weight: bold;
}

.dropdown {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: 2vw;
}

.navDropdown>a {
    text-decoration: none;
    margin: 0 !important;
    display: flex;
    justify-content: center;
    color: #828282 !important;
    font-family: mulish-light !important;
    font-size: 0.9375vw !important;
    font-weight: bold;
}

.usernameContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 10vw;
    flex-direction: row;
}

.username {
    margin-bottom: 0 !important;
    font-family: mulish-light !important;
    font-size: 0.9375vw !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #696969;
}

.menuSection1 {
    width: 25%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 4vw;
    background-image: url("../../../assets/styles/images/LOGO.png");
    background-size: cover;
}

.menuSection2 {
    width: 20%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.menuSection3 {
    width: 10%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.menuSection4 {
    width: 20%;
    display: flex;
    flex-direction: row;
    justify-content: start;
}

.menuSection5 {
    width: 25%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}