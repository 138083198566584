.sectionSingleNewsNews{
    display: flex;
    flex-direction: row;
    height: auto;
    width: 100%;
}

.singleNewsSection{
    display: flex;
    flex-direction: column;
    height: auto;
    width: 75%;
    align-items: center;
}

.sectionNews{
    display: flex;
    flex-direction: column;
}

.grayColor{
    color: #696969;
}

.descriptionContainer{
    margin-top: 5vh;
}

.mainPicture{
    display: flex;
    flex-direction: row;
    margin-top: 10.159958720330238vh;
    width: 60.20833333333333vw;
    height: 91.84726522187823vh;
}

.mainImg{
    border-radius: 15px;
    width: 100%;
    height: 100%;
}

.dateOfCreation{
    display: flex;
    padding-right: 4%;
    padding-top: 6%;
    color: white;
}

.row{
    display: flex;
    flex-direction: row;
}