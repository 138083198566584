.row{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.buttonTitle{
    font-size: xx-large;
    color: gray;
    border: 0px;
    border-radius: 10px;
}

.errorMessage{
    color:#D8000C;
    font-family: mulish-light;
    font-size: 1.04375vw;
}