.usersPage{
    flex-direction: column;
}

.sectionUsersNews{
    display: flex;
    flex-direction: row;
    height: auto;
    width: 100%;
}

.usersSection{
    display: flex;
    flex-direction: column;
    height: auto;
    width: 75%;
    align-items: center;
}

.arrowsRow{
    display: flex;
    flex-direction: row;
    width: 85%;
    justify-content: flex-end;
}

.sectionNews{
    display: flex;
    flex-direction: column;
}

.tableRow {
    display: flex;
    flex-direction: row;
    margin-top: 5vh;
    width: 90%;
}

.table{
    background-color: white;
}

.icon:hover{
    cursor: pointer;
}

