.videoGallery {
    display: flex;
    flex-direction: row;
    margin-top: 2.223942208462332vh;
    padding-left: 10.20833333333333vw;
    width: 100%;
    height: 60.959752321981423vh;
}

.container {
    border-radius: 0;
}

.videoCard {
    margin: 0.75vw;
    margin-left: 2.0833333333333335vw;
    margin-right: 2.0833333333333335vw;
    border-radius: 10px;
    width: 35.677083333333336vw;
    height: 47.083591331269346vh;
    display: flex;
    flex-direction: column;
}

.photoImg {
    width: 100%;
    height: 28.379772961816304vh;
    border-radius: 10px;
}

.paginationSection {
    display: flex;
    flex-direction: row;
    width: 95%;
    height: 10%;
    align-items: flex-end;
    justify-content: flex-end;
    column-gap: 10px;
    margin-top: 30px;
}

.arrowCol {
    display: flex;
    flex-direction: column;
    border-radius: 50%;
    background-color: #1C8693;
}

.arrowCol:hover {
    cursor: pointer;
}

.arrowColWithOpacity {
    display: flex;
    flex-direction: column;
    border-radius: 50%;
    background-color: #1C8693;
    opacity: 0.5019607843137255;
}

.row {
    display: flex;
    flex-direction: row;
}

.image {
    margin: 7%;
}

.img {
    height: 50% !important;
}