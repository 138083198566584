.sectionClientNews{
    display: flex;
    flex-direction: row;
    height: auto;
    width: 100%;
    min-height: 90vh;
}

.sectionClient{
    height: auto;
    display: flex;
    flex-direction: column;
    width: 75%;
}

.sectionNews{
    height: auto;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    width: 25%;
}

.row{
    display: flex;
    flex-direction: row;
}
