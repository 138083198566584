.rowArrows{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.paginationCol{
    display: flex;
    flex-direction: column;
    width: 10%;
}

.paginationSection{
    display: flex;
    width: 100%;
    height: 10%;
    align-items: flex-end;
    justify-content: center;
    column-gap: 10px;
    margin-top: auto;
}

.arrowCol{
    display: flex;
    flex-direction: column;
    border-radius: 50%;
    background-color: #1C8693;
}

.arrowCol:hover{
    cursor: pointer;
    opacity: 0.8;
}

.arrowColWithOpacity{
    display: flex;
    flex-direction: column;
    border-radius: 50%;
    background-color: #1C8693;
    opacity: 0.5019607843137255;
}

.arrowColWithOpacity:hover{
    cursor: pointer;
}