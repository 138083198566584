.headerTopLine {
   display: flex;
   flex-direction: column;
   width: 100%;
   height: 2.3545706371191137vh;
   background-image: url("../../../assets/styles/images/home/header/HEADER\ TOP\ LINE.png");
   background-size: cover;
}

.header {
   display: flex;
   flex-direction: column;
   width: 100%;
   background-position: center;
   background-size: cover;
}

.header .wrapper {
   display: flex;
   flex-direction: row;
   width: 100%;
}

.headerImage {
   display: flex;
   flex-direction: column;
   width: 100%;
   background-position: center;
   background-size: cover;
}

.headerTitleContainer {
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
   width: 100%;
   height: 100%;
   text-align: center;
}

.headerTitle {
   color: #fff;
   font-size: 7.5685903500473035vh;
   font-family: minion-pro-semibold;
   margin-bottom: 0 !important;
   width: 65%;
}

.slider {
   display: flex !important;
   flex-direction: row;
   align-items: center;
   justify-content: center;
}

.sliderContainer {
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;

}