.routeTitleContainer {
    display: flex;
    flex-direction: row;
}

.col {
    display: flex;
    flex-direction: column;
    width: 75%;
}

.routeTitle {
    opacity: 1;
    padding: 0.1vw;
    fill: #696969;
    font-family: red-hat-display-bold;
    fill-rule: evenodd;
    font-weight: bold;
    font-size: 0.8vw;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0 !important;
}

.deleteIconCol {
    display: flex;
    flex-direction: column;
    width: 5%;
    visibility: hidden;
    margin-right: 0.5vw;
    align-self: center;
}

.deleteIconCol:hover {
    cursor: pointer;
}