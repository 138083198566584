.descriptionSection{
    display: flex;
    flex-direction: row;
    margin-top: 5vh;
}

.description{
    font-family: mulish-light !important;
    font-size: 1.3816666666666667vw !important;
    margin-left: 1.5em;
    color: #818181 !important;
}

.description * {
    font-family: mulish-light !important;
    font-size: 1.3816666666666667vw !important;
    color: #818181 !important;
}