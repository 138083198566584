.sectionTitle {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 6vh;
}

.titleContainer {
    font-size: 2.34375vw;
    font-family: red-hat-display-bold;
    width: 100%;
    flex-direction: row;
    display: flex;
    align-items: center;
}

.iconImg {
    display: flex;
    flex-direction: column;
    margin-left: 5%;
    margin-right: 0.5vw;
    width: 4.5416666666666665vw;
}

.blueColorLight {
    color: #1C8693;
}

.blueColorDark {
    color: #03464B;
}


.categoryCol {
    height: 2.815649867374005vh;
    border-radius: 15px;
    background-color: #DCDCDC;
    align-self: center;
    font-family: mulish-light;
    font-size: 0.921666666666666vw;
    align-content: center;
    text-align: center;
    margin-left: 5vw;
    padding-left: 1vw;
    padding-right: 1vw;
    margin-left: 40%;
    opacity: 0.9;
}

.likeCol {
    font-family: mulish-light;
    font-weight: bold;
    color: #696969;
    font-size: 2.038390092879257vh;
    align-self: center;
    margin-left: 2vw;
    opacity: 0.9;
}

.likeBtn {
    color: #1C8693;
    opacity: 0.9;
}

.likeBtn:hover {
    cursor: pointer;
}

.likedBtn {
    color: #03464B;
}

.rateCol {
    display: flex;
    flex-direction: column;
    margin-left: 3.3416666666666667vw;
    background-color: #fbdc90;
    padding-left: 0.7416666666666667vw;
    padding-right: 0.7416666666666667vw;
    border-radius: 15px;
    height: 2.838390092879257vh;
    align-self: center;
}

.row {
    display: flex;
    flex-direction: row;
}

.imgStar {
    object-fit: contain;
    margin-top: 0.4vh;
    width: 70%;
    height: 70%;
}

.rate {
    font-family: mulish-light;
    font-weight: bold;
    color: #696969;
    font-size: 1.538390092879257vh;
    margin-left: 0.6416666666666667vw;
    margin-top: 0.30959752321981426vh;
}