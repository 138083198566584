.sectionRouteNews {
    display: flex;
    flex-direction: row;
    height: auto;
    width: 100%;
}

.routeSection {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 75%;
    align-items: center;
    padding-left: 1%;
    padding-right: 1%;
}

.descriptionSection {
    display: flex;
    flex-direction: row;
    margin-top: 3.159958720330238vh;
}

.description {
    font-family: mulish-light;
    font-size: 1.0416666666666667vw;
    margin-left: 7.0416666666666667vw;
    margin-right: 7.0416666666666667vw;
    color: #696969;
}

.sectionNews {
    display: flex;
    flex-direction: column;
}