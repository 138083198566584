.commentCard{
    margin-left: 5em;
    width: 73%;
    height: auto;
    border-radius: 10px;
    background-image: linear-gradient(to left top, #e8e8e8, white, white, white, #e8e8e8);
}

.commentNameContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.commentNameCol{
    display: flex;
    flex-direction: column;
    padding-left: 1.0416666666666667vw;
    padding-top: 1.0416666666666667vw;
    border-radius: 10px;
}

.commentName{
    color: #B47E38;
    font-family: mulish-extrabold;
    font-size: 20px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.row{
    display: flex;
    flex-direction: row;
}

.btnCol{
    margin-right: 2vw;
    align-self: center;
}

.btnApprove{
    font-family: mulish-light;
    font-size: 16px;
    color: #1C8693;
    text-align: center;
    width: 5.854166666666666vw;
    height: 3.23342175066313vh;
    border-radius: 10px;
    border-color: #1C8693;
    border-width: thin;
    background-color: white;
}

.btnHide{
    font-family: mulish-light;
    font-size: 16px;
    color: #B47E38;
    text-align: center;
    width: 5.854166666666666vw;
    height: 3.23342175066313vh;
    border-radius: 10px;
    border-color: #B47E38;
    border-width: thin;
    background-color: white;
}

.commentDate{
    color: #696969;
    font-family: mulish-extrabold;
    font-size: 14px;
    margin-top: 2.5vh;
    margin-right: 1.0416666666666667vw;
}

.commentDescriptionContainer{
    display: flex;
}

.commentDescription{
    color: #696969;
    font-family: mulish-light;
    font-size: 20px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 1.0416666666666667vw;
}