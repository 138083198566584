.clientCategoriesSection {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    flex-wrap: wrap;
}

.clientCol {
    width: 100%;
    height: 3.3959752321981426vh;
    font-family: mulish-light;
    font-size: 1.5319917440660475vh;
    font-weight: bold;
    color: #696969;
    margin-top: 1.2604166666666667vh;
}

.rowCategories {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.categoryIcon {
    width: 13%;
    height: 3.0959752321981426vh;
}

.categoryName {
    margin-left: 5%;
    width: 77%;
}

.number {
    margin-left: 2%;
    font-family: mulish-extrabold;
    font-size: 0.8vw;
}