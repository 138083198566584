.productCardContainer {
  height: 35.379772961816304vh;
}

.productCard {
  margin: 0.75vw;
  border-radius: 5%;
  width: 10vw;
  display: flex;
  flex-direction: column;
}

.productCard:hover .iconCol {
  visibility: visible;
  display: flex;
}

.productCard:hover .titleCol {
  width: 78%;
}

.link {
  text-decoration: none;
}

.imgProduct {
  width: 100%;
  height: 21.379772961816304vh;
  object-fit: cover;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 10vw;
  margin-top: 1vw;
}

.categoryInTitle {
  display: flex;
  flex-direction: column;
  border-radius: 0.5vw;
  background-color: #ececec;
  justify-content: center;
  align-items: center;
}

.category {
  font-family: mulish-regular;
  padding: 0.15vw;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0 !important;
  font-size: 0.8vw;
}

.titleContainer {
  display: flex;
  flex-direction: row;
}

.titleCol {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.productTitle {
  opacity: 1;
  padding: 0.1vw;
  fill: #696969;
  font-family: red-hat-display-bold;
  fill-rule: evenodd;
  display: flex;
  font-weight: bold;
  flex-direction: row;
  font-size: 0.8vw;
  margin-bottom: 0 !important;
}

.iconCol {
  display: none;
  flex-direction: column;
  width: 5%;
  margin-left: 0.5vw;
  visibility: hidden;
}

.deleteIcon:hover {
  cursor: pointer;
}
