.label{
    font-family: mulish-regular;
    font-size: 18px;
    color: #B47E38;
    margin-left: 1.5625vw;
    width: 100%;
}

.inputName{
    width: 90%;
    height: 5vh;
    border-radius: 10px;
    border-color: rgb(158, 158, 158, 0.25);
    border-width: thin;
    margin-top: 1.0319917440660475vh;
}

.inputName:focus{
    outline: none;
    border-color: rgb(158, 158, 158, 0.5);
}

.row{
    display: flex;
    flex-direction: row;
}

.inputDescription{
    width: 70%;
    height: 10vh;
    border-radius: 10px;
    border-color: rgb(158, 158, 158, 0.25);
    border-width: thin;
    margin-top: 1.0319917440660475vh;
}

.inputDescription:focus{
    outline: none;
    border-color: rgb(158, 158, 158, 0.5);
}

.btnContainer {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    justify-content: center;
    width: 20%;
}

.btnSubmitComment {
    font-size: 16px;
    font-family: mulish-light;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    color: #1C8693;
    border: 0px;
    cursor: pointer;
    border-radius: 10px;
    border-color: #1C8693;
    border-width: thin;
    background-color: transparent;
    border: 1px solid #1C8693;
}