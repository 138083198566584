.formContainer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 100%;
    align-content: center;
}

.form {
    width: 50%;
    align-items: center;
    justify-content: center;
    padding: 1vw 0;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.labelclass {
    font-size: 0.8635416666666665vw;
    font-family: mulish-light;
    color: #03464b;
}

.form-label {
    margin-bottom: 0 !important;
}


.messageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0.5vw;
}

.errorMessage {
    color: red;
    margin-bottom: 0 !important;
    font-size: 0.7vw;
    font-family: mulish-light;
}

.successMessage {
    color: green;
    margin-bottom: 0 !important;
    font-size: 0.7vw;
    font-family: mulish-light;
}

.submitBtnContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 1vw;
}

.btnSubmit {
    background-color: transparent;
    width: auto;
    height: auto;
    border-radius: 0.5vw;
    border: 0.1vw solid #1c8693;
    align-items: center;
    justify-content: center;
}

.txtSubmit {
    color: #1c8693;
    font-size: 1.0364583333333333vw;
    font-family: mulish-light;
    font-weight: bold;
    margin-bottom: 0 !important;
    padding: 0.3vw;
}

.facebookBtn {
    width: 100%;
    background: #3B5998;
    color: white;
    text-align: center;
    text-decoration: none;
    font-size: 1.0364583333333333vw;
    height: 2.5520833333333335vw;
    border-color: #3B5998;
    border-radius: 0.5vw;
    align-items: center;
    justify-content: center;
    font-family: mulish-light;
}

.facebookBtn:hover {
    opacity: 0.7;
}

.googleBtn {
    width: 100%;
    align-items: center;
    justify-content: center;
    border-radius: 0.5vw !important;
    height: 2.5520833333333335vw !important;
    font-size: 1.0364583333333333vw !important;
    font-family: mulish-light !important;
}

.googleBtn:hover {
    opacity: 0.7;
}

.googleBtn>div {
    display: flex !important;
    width: 2vw !important;
    height: 2.5520833333333335vw !important;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
    margin-right: 0 !important;
}