.title {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 9.0959752321981426vh;
    align-items: center;
}

.icon {
    display: flex;
    flex-direction: column;
    margin-left: 5%;
    margin-right: 0.5vw;
    width: 4.5416666666666665vw;
}

.routeNameTitle {
    display: flex;
    flex-direction: row;
    font-size: 36px;
    font-family: red-hat-display-bold;
    padding: 5px;
}

.blueColorLight {
    color: #1C8693;
}

.blueColorDark {
    color: #03464B;
}

.rateCol {
    display: flex;
    flex-direction: column;
    margin-left: 3.3416666666666667vw;
    background-color: #fbdc90;
    padding-left: 0.7416666666666667vw;
    padding-right: 0.7416666666666667vw;
    border-radius: 15px;
    height: 2.838390092879257vh;
    align-self: center;
}

.row {
    display: flex;
    flex-direction: row;
}

.rate {
    font-family: mulish-light;
    font-weight: bold;
    color: #696969;
    font-size: 1.538390092879257vh;
    margin-left: 0.6416666666666667vw;
    margin-top: 0.30959752321981426vh;
}

.duration {
    font-size: 36px;
    font-family: red-hat-display-bold;
    padding: 5px;
    margin-left: 4.0041666666666665vw;
}

.starsCol {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin-left: 6.0041666666666665vw;
    margin-bottom: 1vh;
    font-family: mulish-light;
    font-size: 1.0291666666666666vw;
    text-align: center;
    color: #696969;
}

.facebookCol {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin-left: 4.0041666666666665vw;
}

.descriptionSection {
    display: flex;
    flex-direction: row;
    margin-top: 3.159958720330238vh;
}

.descriptionCurrent {
    font-family: mulish-light !important;
    color: #696969 !important;
    font-size: 1.0416666666666667vw !important;
    margin-left: 7.0416666666666667vw;
    margin-right: 7.0416666666666667vw;
}

.descriptionCurrent * {
    font-family: mulish-light !important;
    color: #696969 !important;
    font-size: 1.0416666666666667vw !important;
}