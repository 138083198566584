.mapSection{
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    justify-content: center;
    height: 51.59958720330237vh;
    padding-left: 2%;
    padding-right: 2%;
    margin-top: 3vh;
}

.map{
    width: 100%;
    height: 100%;            
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
}

.dropdown{
    border: 0px;
    background-color: white;
    position: absolute;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 9;
    width: 20%;
    height: 25%;
    top: 0.5%;
    left: 79.8%;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    align-content: center;
    text-align: center;
}

.rowEnd{
    display: flex;
    flex-direction: row;
    justify-content: end;
}

.btnShow{
    font-family: mulish-light;
    font-size: 16px;
    color: #1C8693;
    text-align: center;
    height: 4.23342175066313vh;
    border-radius: 10px;
    border-color: #1C8693;
    border-width: thin;
    background-color: white;
}

.pointform {
    border: 0px;
    background-color: white;
    position: absolute;
    overflow-x: hidden;
    z-index: 9;
    width: 20%;
    top: 26%;
    left: 79.8%;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    align-content: center;
    text-align: center;
    padding-bottom: 1px;
}

.pointInputGroup {
    width: 80%;
    padding-left: 20%;
}

.errorMessage {
    color:#D8000C;
    font-family: mulish-light;
    font-size: 1.04375vw;
}


.rowEnd {
    display: flex;
    flex-direction: row;
    justify-content: end;
}