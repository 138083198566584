.title{
    font-size: xx-large;
    color: #818181;
}

.row{
    display: flex;
    flex-direction: row;
    margin-top: 2vh;
}

.col{
    display: flex;
    flex-direction: column;
    width: 96%;
    margin-left: 4%;
}