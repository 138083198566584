.arrowsRow {
    width: 85%;
}

.sectionServicesContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 5vw;
    margin-right: 5vw;
}

.servicesContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1vw;
}

.services {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: auto;
    justify-content: center;
    column-gap: 1.5vw;
}