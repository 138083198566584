
 .centerDiv{
    position: absolute;
    top: 70%;
    width: 100%;
  }
  
  .aplication {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 38.989583333333332vw;
    background-image: url("../../assets/styles/images/home/PREUZMITE_APLIKACIJU.png");
    background-size: cover;
  }
  
  .aplicationInfoContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 80%;
    margin-left: 3vw;
  }
  
  .downloadAppTxtContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: 1vw;
  }
  
  .downloadAppTxt {
    color: #ffffff;
    font-size: 2.875vw;
    font-family: grendelbold;
    margin-bottom: 0 !important;
    margin-left: 12vw;
  }
  
  .AppDescriptionContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 1vw;
  }
  
  .downloadAppDescription {
    color: #ffffff;
    font-size: 1.0416666666666667vw;
    font-family: mulish-light;
    margin-bottom: 0 !important;
  }
  
  .downloadAppBtnsContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
  }
  
  .buttonDownloadApp {
    border: none;
    background-color: transparent;
    width: auto;
    height: auto;
    padding: 0 !important;
    margin-right: 1vw;
  }
  
  .buttonDownloadApp:hover {
    background-image: url("../../assets/styles/images/register-login/DOWNLOAD_ONMOUSE_OVER.png");
  }
  
  .downloadPlayStore {
    display: flex;
    flex-direction: row;
    background-image: url("../../assets/styles/images/register-login/PLAY\ STORE.png");
    background-size: cover;
    width: 28.994166666666666vw;
    height: 8.5854166666666665vw;
    align-items: center;
    justify-content: center;
  }
  
  .downloadAppStore {
    display: flex;
    flex-direction: row;
    background-image: url("../../assets/styles/images/register-login/APP\ STORE.png");
    background-size: cover;
    width: 28.994166666666666vw;
    height: 8.5854166666666665vw;
    align-items: center;
    justify-content: center;
  }
  
