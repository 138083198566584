.logoContainer{
    display: flex;
    flex-direction: column;
    width: 25%;
    height: auto;
    align-items: center;
    margin-top: 5vh;
}

.clientDetailsRow{
    display: flex;
    flex-direction: row;
    width: 80%;
    justify-content: center;
    margin-top: 1vh;
    color: #696969;
    font-family: mulish-bold;
    font-size: 2.063983488132095vh;
}

.clientDetailsRow:hover{
    cursor: pointer;
}

.placeRow{
    display: flex;
    flex-direction: row;
    width: 80%;
    justify-content: center;
    margin-top: 1vh;
    color: #696969;
    font-family: mulish-bold;
    font-size: 2.063983488132095vh;
}

.img:hover{
    cursor: pointer;
}