.shareButtton {
  width: 6.380208333333333vw;
  height: 3.515649867374005vh;
  border-radius: 15px;
  background-color: #dcdcdc;
  align-self: center;
  font-family: mulish-light;
  align-content: center;
  text-align: center;
  margin-left: 5vw;
  cursor: pointer;
}

/* Modal container */
.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

/* Modal content */
.modalContent {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 6.160164271047228vh;
  border: 1px solid #888;
  width: 40%;
  color: #828282 !important;
  font-family: mulish-light !important;
  font-size: 1.6375vw !important;
  font-weight: bold;
}

/* Social media sharing buttons */
.shareButtons {
  display: flex;
  margin-top: 20px;
  justify-content: center;
}

.shareButtonSocial {
  cursor: pointer;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
}

.shareButtonSocial:hover {
  cursor: pointer;
  opacity: 0.8;
}

/* Close button */
.close {
  color: darkgray;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.linkContainer {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.linkInput {
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 100%;
}

.copyLink {
  margin-left: 5px;
  border: none;
  border-radius: 5px;
  padding: 5px;
}

.copyLink:hover {
  background-color: #e2e2e2;
}
