.pictureGallery{
    display: flex;
    flex-direction: row;
    width: 70%;
    justify-content: center;
    height: 300px;
}  

.container {
    border-radius: 0;
    width: 100%;
}

.photoCard{
    margin: 0.5vw;
    border-radius: 10px;
    width: 28.379772961816304vh;
    height: 28.379772961816304vh;
    display: flex;
    flex-direction: column;
}

.photoImg{
    width: 100%;
    height: 28.379772961816304vh;
    border-radius: 10px;
}

.paginationSection{
    display: flex;
    flex-direction: row;
    width: 95%;
    height: 10%;
    align-items: flex-end;
    justify-content: flex-end;
    column-gap: 10px;
    margin-top: 30px;
}

.arrowCol{
    display: flex;
    flex-direction: column;
    border-radius: 50%;
    background-color: #1C8693;
}

.arrowCol:hover{
    cursor: pointer;
}

.arrowColWithOpacity{
    display: flex;
    flex-direction: column;
    border-radius: 50%;
    background-color: #1C8693;
    opacity: 0.5019607843137255;
}

.row{
    display: flex;
    flex-direction: row;
}

.image{
    margin: 7%;
}

.img{
    height: 50% !important;
}