.filterAndPaginationSection{
    display: flex;
    justify-content: start;
    flex-direction: row;
    width: 100%;
}

.plusLink{
    display: flex;
    flex-direction: column;
    width: 25%;
    font-size: xx-large;
    align-self: center;
}

.plusLinkDesign{
    font-size: xx-large;
    color: #1C8693;
    font-family: mulish-light;
    text-decoration: none;
}

.filterCol{
    display: flex;
    justify-content: start;
    flex-direction: column;
    width: 60%;
}

.filterSection{
    width: 100%;
    display: flex;
    justify-content: start;
    flex-direction: row;
    background-color: #ECECEC;
    border-radius: 20px;
    height: auto;
}

.filterOnlyMy{
    display: flex;
    flex-direction: column;
    width: 20%;
    align-items: center;
}

.row{
    display: flex;
    flex-direction: row;
}

.marginTop{
    margin-top:20px;
}

.filterCategory{
    display: flex;
    flex-direction: column;
    width: 27%;
    align-items: center;
}

.filterCategoryWide{
    display: flex;
    flex-direction: column;
    width: 35%;
    align-items: center;
}

.filterTitlesColor{
    color: #197d89;
    font-family: roboto-bold;
    font-size: 12px;
}

.filterCategoryIcon{
    width: 1.6666666666666667vw;
    height: 3.199174406604747vh;
    color: #696969;
    margin-top: 1vh;
    margin-left: 1vw;
}

.categories{
    font-family: roboto-bold;
    font-size: 14px;
}

.filterName{
    display: flex;
    flex-direction: column;
    margin-left: 5%;
    width: 35%;
}

.filterNameWide{
    display: flex;
    flex-direction: column;
    margin-left: 5%;
    width: 45%;
}

.filterCategoryRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.dotsIcon{
    width: 1.5625vw;
    height: 0.4166666666666667vw;
    margin-right: 2.5vw;
}

.inputClientName{
    border-radius: 8px;
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: hidden;
    width: 13.020833333333334vw;
    font-family: roboto-regular;
}

.filterSearch{
    display: flex;
    flex-direction: column;
    width: 5.668169761273209vh;
    background-color: #1C8693;
    border-radius: 15px;
    height: 5.668169761273209vh;
    margin-top: 2.0262599469496021vh;
    justify-content: center;
    align-items: center;
}

.filterSearch:hover{
    cursor: pointer;
    opacity: 0.8;
}

.filterIcon{
    display: flex;
    flex-direction: column;
    background-image: url("../../../../assets/styles/images/ikonicaSearch.png");
    background-size: cover;
    width: 25px;
    height: 25px;
}