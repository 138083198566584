.firstRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 3vw;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.title {
    font-size: xx-large;
    color: #818181;
}

.checkboxGroup {
    display: flex;
    flex-direction: row;
    height: 3vh;
    align-items: center;
}

.checkbox {
    display: flex;
    align-items: center;
    padding-left: 0.75vw;
}

.basicRow {
    display: flex;
    flex-direction: row;
}

.errorMessage {
    color: #D8000C;
    font-family: mulish-light;
    font-size: 1.04375vw;
}

.formGroup {
    width: 33%;
}

.formGroupWithPadding {
    width: 33%;
    padding-left: 2%;
}