.home {
    flex-direction: column;
    height: 100%;
}

.sectionMap {
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.sectionClientsNews {
    display: flex;
    flex-direction: row;
    height: auto;
    width: 100%;
}

.sectionClients {
    height: auto;
    display: flex;
    flex-direction: column;
    width: 75vw !important;
    background-color: #fff;
}

.sectionNews {
    height: auto;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    width: 25vw !important;
}

.rpuImg {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 74.26679280983917vh;
    background-image: url("../../assets/styles/images/home/RPU\ background.png");
    background-size: cover;
    background-position: center;
}

.sectionProducts {
    height: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.onpnImg {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 96.02649006622516vh;
    background-image: url("../../assets/styles/images/home/ONPN\ background.png");
    background-size: cover;
    background-position: center;
}

.sectionServices {
    height: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.sectionAbout {
    height: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
}

hr.homeHr {
    margin-left: 5%;
    width: 90%;
    color: rgb(28, 134, 147)
}