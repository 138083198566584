.buttonTitle{
    font-size: x-large;
    color: gray;
    border: 0px;
    border-radius: 1.0319917440660475vh;
    width: 100%;
    height: 5vh;
}

.errorMessage{
    color:#D8000C;
    font-family: mulish-light;
    font-size: 1.04375vw;
}

.plus{
    color : gray;
    width: 20%;
    height: 100%;
}

.img{
    margin-top: 4vh;
    width: 100%;
    height: 19.3vh;
}