.servicePage{
    flex-direction: column;
}

.sectionServiceNews{
    display: flex;
    flex-direction: row;
    height: auto;
    width: 100%;
}

.serviceSection{
    display: flex;
    flex-direction: column;
    height: auto;
    width: 75%;
    align-items: center;
}

.sectionNews{
    display: flex;
    flex-direction: column;
}

.descriptionLogoSection{
    margin-top: 2em;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.descriptionContainer{
    width: 70%;
}

.mainPicture{
    display: flex;
    flex-direction: row;
    width: 50.72916666666667vw;
    height: 70.63364293085655vh;
    justify-content: center;
    margin-top: 10.159958720330238vh;
}

.mainImg{
    border-radius: 10px;
}