.row{
    display: flex;
    flex-direction: row;
}

.ratingCol{
    margin-left: 2vw;
    font-family: mulish-light;
    font-size: 1.0291666666666666vw;
}
