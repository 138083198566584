.viewAllContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 6vw;
    margin-right: 2vw;
    margin-top: 2vw;
}

.btnViewAll {
    background-color: transparent;
    width: 7.083333333333333vw;
    height: 2.4479166666666665vw;
    border-radius: 0.5vw;
    border: 0.1vw solid #1c8693;
    align-items: center;
    justify-content: center;
}

.txtViewAll {
    color: #1c8693;
    font-size: 1.5vh;
    font-family: mulish-light;
    font-weight: bold;
    margin-bottom: 0 !important;
}