.servicesPage {
    flex-direction: column;
}

.sectionServicesNews {
    display: flex;
    flex-direction: row;
    height: auto;
    width: 100%;
}

.servicesSection {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 75%;
    align-items: center;
}

.services {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    height: auto;
    column-gap: 3%;
    justify-content: center;
    margin-top: 3.305039787798409vh;
}

.sectionNews {
    display: flex;
    flex-direction: column;
}