.circleButton {
  border-radius: 9999px;
  width: 2rem;
  height: 2rem;
  font-size: 2rem;
  line-height: 1rem;
  background-color: #1c8693;
  padding: 0;
  border: 0;
  vertical-align: middle;
  color: white;
}

.circleButton:hover {
  opacity: 0.8;
}

.inputContainer {
  display: flex;
  overflow: hidden;
  align-items: center;
  border-radius: 0.375rem;
  text-align: center;
}

.manageCartButton {
  border-radius: 10px;
  line-height: 1rem;
  background-color: #1c8693;
  padding: 0;
  border: 0;
  vertical-align: middle;
  color: white;
  height: 2rem;
  margin: 3px;
  padding-left: 1rem;
  padding-right: 1rem;
}

.manageCartButton:hover {
  opacity: 0.8;
}
