.mainContainer {
  display: flex;
  flex-direction: row;
  height: auto;
  width: 100%;
}

.loginContainer {
  height: auto;
  display: flex;
  flex-direction: column;
  width: 75vw;
  background-color: #fff;
}

.titleRegisterLogin {
  width: 100%;
  height: 8vw;
  display: flex;
  flex-direction: row;
  margin-bottom: 0 !important;
  align-items: center;
  justify-content: flex-start;
}

.sectionDescriptionLoginRegister {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 6vw;
  margin-right: 6vw;
  height: 75.55248618802084vw;
}

.descriptionContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 10vw;
  align-items: center;
}

.description {
  display: flex;
  flex-direction: column;
  font-family: mulish-light;
  color: #8f8f8f;
  font-size: 1.0416666666666667vw;
  margin-bottom: 0 !important;
  padding: 1vw;
}

.loginContentContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 60.55vw;
  width: 100%;
}

.mainBox {
  display: flex;
  height: 22.5vw;
  width: 34.583333333333336vw;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.blueBox {
  width: auto;
  height: auto;
  min-width: 27.135416666666668vw;
  min-height: 22.5vw;
  display: flex;
  flex-direction: column;
  border-radius: 1vw;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, #1C8693, #03464b);
}

.tabsContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 3.4375vw;
  align-items: center;
  justify-content: center;
}

.tabLogin {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 13.54166667vw;
  cursor: pointer;
  height: 100%;
}

.tabLoginActive {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 13.54166667vw;
  cursor: pointer;
  height: 100%;
  background: linear-gradient(to right, #1C8693, #03464b);
  border-top-left-radius: 1vw;
}

.tabLoginActive .tabTxtLogin {
  color: #eaa73a !important;

}

.tabLogin:hover {
  background: linear-gradient(to right, #1C8693, #03464b);
  border-top-left-radius: 1vw;
}

.tabLogin:hover .tabTxtLogin {
  color: #eaa73a !important;

}

.tabTxtLogin {
  color: #ffffff;
  font-size: 1.0364583333333333vw;
  font-family: mulish-light;
  font-weight: bold;
  margin-bottom: 0 !important;
}

.divider {
  display: flex;
  flex-direction: row;
  width: 0.052083333333333336vw;
  height: 2.3958333333333335vw;
  background-image: url("../../../assets/styles/images/register-login/DIVIDER_BLUE_BOX.png");
  background-size: cover;
}

.tabRegister {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 13.54166667vw;
  cursor: pointer;
  height: 100%;
}

.tabRegisterActive {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 13.54166667vw;
  cursor: pointer;
  height: 100%;
  background: linear-gradient(to left, #1C8693, #03464b);
  border-top-right-radius: 1vw;
  color: #eaa73a !important;
}

.tabRegisterActive .tabTxtRegister {
  color: #eaa73a !important;

}

.tabRegister:hover {
  background: linear-gradient(to left, #1C8693, #03464b);
  border-top-right-radius: 1vw;
}

.tabRegister:hover .tabTxtRegister {
  color: #eaa73a !important;

}

.tabTxtRegister {
  color: #ffffff;
  font-size: 1.0364583333333333vw;
  font-family: mulish-light;
  font-weight: bold;
  margin-bottom: 0 !important;
}

.grayBox {
  display: flex;
  margin-left: -3.72395834vw;
  margin-right: -3.72395834vw;
  height: auto;
  width: auto;
  min-height: 15.625vw;
  min-width: 32.1875vw;
  align-items: center;
  justify-content: center;
  background-color: #ECECEC;
  border-radius: 1vw;
  box-shadow: 0px 0px 27px 0px rgba(1, 1, 1, 0.24);
}

.sectionNews {
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 25vw;
}

.forgotPasswordContainer {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.forgotPasswordTxt {
  color: #ffffff;
  font-size: 0.8635416666666665vw;
  font-family: mulish-light;
  margin-bottom: 0 !important;
}

.forgotPasswordLink {
  color: #eaa73a;
  font-size: 0.8635416666666665vw;
  font-family: mulish-light;
  margin-bottom: 0 !important;
  cursor: pointer;
}