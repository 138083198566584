.titleRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.icon {
    display: flex;
    flex-direction: column;
    margin-left: 5%;
    margin-right: 0.5vw;
    width: 4.5416666666666665vw;
}

.title {
    display: flex;
    flex-direction: row;
    font-size: 2.34375vw;
    ;
    font-family: red-hat-display-bold;
    padding: 5px;
    width: 80%;
}

.blueColorDark {
    color: #03464B;
}

.shareBtn {
    width: 6.208333333333333vw;
    height: 3.579979360165119vh;
    border-radius: 10px;
    background-color: #dcdcdc;
    margin-top: 3.0319917440660475vh;
    padding-left: 1.579979360165119vh;
    padding-top: 0.279979360165119vh;
    font-family: mulish-light;
    font-size: 0.9291666666666666vw;
}

.row {
    display: flex;
    flex-direction: row;
}

.shareBtn:hover {
    cursor: pointer;
}