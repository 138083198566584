.filterContainer {
  width: 100%;
  min-height: 10vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.filterTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.blueLineLeft {
  display: flex;
  flex-direction: row;
  width: 13.333333333333334vw;
  height: 0.052083333333333336vw;
  background-image: url("../../../assets/styles/images/home/filter/BLUE\ LINE\ LEFT.png");
  background-size: cover;
}

.selectClientContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
}

.selectClientTxt {
  display: flex;
  flex-direction: row;
  font-family: mulish-regular;
  color: #1c8693;
  font-size: 0.9375vw;
  margin-bottom: 0 !important;
  font-weight: bold;
}

.blueLineRight {
  display: flex;
  flex-direction: row;
  width: 13.489583333333334vw;
  height: 0.052083333333333336vw;
  background-image: url("../../../assets/styles/images/home/filter/BLUE\ LINE\ RIGHT.png");
  background-size: cover;
}

.filterBox {
  display: flex;
  flex-direction: row;
  width: 46vw;
  min-height: 4.583333333333333vw;
  height: auto;
  background-color: #ececec;
  border-radius: 1vw;
  align-items: center;
  justify-content: center;
}

.filterCategoryBox {
  display: flex;
  flex-direction: column;
  width: 18.69vw;
  align-items: center;
  justify-content: center;
}

.filterBoxSection1 {
  display: flex;
  flex-direction: row;
  width: 75%;
  align-items: center;
  justify-content: center;
}

.filterBoxNameContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.filterBoxName {
  display: flex;
  flex-direction: row;
  font-family: roboto-regular;
  color: #197d89;
  font-size: 0.625vw;
  margin-bottom: 0 !important;
  font-weight: bold;
}

.filterBoxImgContainer {
  display: flex;
  flex-direction: row;
  width: 20%;
  align-items: center;
  justify-content: flex-end;
}

.filterBoxImg {
  display: flex;
  flex-direction: row;
  width: 1.5625vw;
  height: 0.4166666666666667vw;
  background-image: url("../../../assets/styles/images/home/filter/dots.png");
  background-size: cover;
}

.filterBoxSection2 {
  display: flex;
  flex-direction: row;
  width: 80%;
  align-items: center;
  justify-content: center;
  z-index: 500 !important;
}

.divider {
  display: flex;
  flex-direction: row;
  width: 0.10416666666666667vw;
  height: 2.7604166666666665vw;
  background-image: url("../../../assets/styles/images/home/filter/DIVIDER.png");
  background-size: cover;
}
