.productsPage {
    flex-direction: column;
}

.sectionProductsNews {
    display: flex;
    flex-direction: row;
    height: auto;
    width: 100%;
}

.productsSection {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 75%;
    align-items: center;
}

.products {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    height: auto;
    column-gap: 3%;
    justify-content: center;
    margin-top: 3.805039787798409vh;
}

.sectionNews {
    display: flex;
    flex-direction: column;
}