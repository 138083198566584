.mainContainer {
    display: flex;
    flex-direction: row;
    height: 28.75vh;
    width: 100%;
}

.pageSection {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.title {
    font-size: xx-large;
    color: #E3842C;
}