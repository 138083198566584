.sectionRouteNews{
    display: flex;
    flex-direction: row;
    height: auto;
    width: 100%;
}

.routeSection{
    display: flex;
    flex-direction: column;
    height: auto;
    width: 75%;
    padding-left: 2%;
    padding-right: 2%;
    margin-top: 4vh;
}

.title{
    font-size: xx-large;
    color: #818181;
}

.rowEnd{
    display: flex;
    flex-direction: row;
    justify-content: end;
}

.btnSave{
    font-family: mulish-light;
    font-size: 16px;
    color: #1C8693;
    text-align: center;
    height: 4.23342175066313vh;
    border-radius: 10px;
    border-color: #1C8693;
    border-width: thin;
    background-color: white;
}

.row{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.colRouteName{
    display: flex;
    flex-direction: column;
    width: 70%;
    height: 30vh;
    margin-top: 3vh;
}

.colImageUpload{
    display: flex;
    flex-direction: column;
    width: 20%;
    margin-left: 5%;
    margin-top: 5vh;
    height: 30vh;
}

.sectionNews{
    display: flex;
    flex-direction: column;
}