.map {
    display: flex;
    justify-content: center;
    width: 100%;
}

.mapContainer {
    height: 70vh;
    width: 100%;
}

.leaflet-popup-content {
    margin: 0 !important;
    width: auto !important;
}

.leaflet-popup-content-wrapper {
    border-radius: 0;
}

.imgClient {
    height: 25vh;
    width: 15vw;
}

.clientName {
    font-weight: bold;
    font-size: large;
    font-family: mulish-light;
    color: #696969;
    padding-left: 5px;
}

.clientDescription {
    font-weight: bold;
    font-size: small;
    font-style: italic;
    color: grey;
    padding-left: 5px;
}

.link {
    text-decoration: none;
}

.clientPopUp {
    cursor: pointer;
    width: 18vw;
}

.descriptionBox {
    overflow: hidden;
    max-height: 15.63983488132095vh;
    width: 100%;
}

.description * {
    font-size: large !important;
    font-family: mulish-light !important;
    color: #696969 !important;
}