body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'grendelbold';
  src: url('./assets/styles/fontovi/grendel/GRENDB__.TTF')
}

@font-face {
  font-family: 'mulish-regular';
  src: url('./assets/styles/fontovi/mulish/Mulish-VariableFont_wght.ttf')
}

@font-face {
  font-family: 'roboto-regular';
  src: url('./assets/styles/fontovi/Roboto/Roboto-Regular.ttf')
}

@font-face {
  font-family: 'roboto-bold';
  src: url('./assets/styles/fontovi/Roboto/Roboto-Bold.ttf')
}

@font-face {
  font-family: 'mulish-light';
  src: url('./assets/styles/fontovi/mulish/static/Mulish-Light.ttf')
}

@font-face{
  font-family: 'mulish-extrabold';
  src: url('./assets/styles/fontovi/mulish/static/Mulish-ExtraBold.ttf')
}

@font-face {
  font-family: 'avant-garde-bold-YU';
  src: url('./assets/styles/fontovi/AvantGardeBoldYU/avant_garde_bold_yu.ttf')
}

@font-face {
  font-family: 'roboto-regular';
  src: url('./assets/styles/fontovi/Roboto/Roboto-Regular.ttf')
}

@font-face {
  font-family: 'minion-pro-semibold';
  src: url('./assets/styles/fontovi/minion-pro-semibold/Minion\ Pro\ Semibold/Minion-Pro-Semibold.ttf')
}

@font-face {
  font-family: 'red-hat-display-bold';
  src: url('./assets/styles/fontovi/red-hat-display/RedHatDisplay-Bold.ttf')
}
