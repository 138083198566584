.clientCardContainer {
    height: 35.379772961816304vh;
}

.clientCard {
    margin: 0.75vw;
    border-radius: 5%;
    width: 10vw;
    display: flex;
    flex-direction: column;
}

.link {
    text-decoration: none;
}

.imgClient {
    width: 100%;
    height: 21.379772961816304vh;
    border-radius: 5%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-top: 0.3vh;
    margin-bottom: 0.3vh;
}

.rowBetween {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 10vw;
    margin-top: 1vw;
}

.categoryContainer {
    display: flex;
    width: 7vw;
    align-items: center;
    justify-content: flex-start;
}

.categoryTag {
    display: flex;
    flex-direction: column;
    width: 6.75vw;
    border-radius: 0.5vw;
    background-color: #ECECEC;
    justify-content: center;
    align-items: center;
}

.category {
    font-family: mulish-regular;
    padding: 0.15vw;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0 !important;
    font-size: 0.8vw;
}

.rateContainer {
    display: flex;
    flex-direction: row;
    width: 3vw;
    align-items: center;
    justify-content: flex-end;
}

.rateTag {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #fbdc90;
    padding: 0.2vw;
    border-radius: 0.5vw;
    height: 2.838390092879257vh;
}

.row {
    display: flex;
    flex-direction: row;
}

.starCol {
    display: flex;
    flex-direction: column;
    width: 0.9114583333333334vw;
    height: 0.9114583333333334vw;
    align-self: center;
}

.rate {
    font-family: mulish-light;
    font-weight: bold;
    color: #696969;
    font-size: 0.9114583333333334vw;
    margin-left: 0.1vw;
    margin-top: 0.1vh;
}

.clientTitleContainer {
    display: flex;
    flex-direction: row;
}

.col {
    display: flex;
    flex-direction: column;
    width: auto;
    max-width: 10vw;
}

.clientTitle {
    opacity: 1;
    padding: 0.1vw;
    fill: #696969;
    font-family: red-hat-display-bold;
    fill-rule: evenodd;
    font-weight: bold;
    font-size: 0.8vw;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0 !important;
}

.deleteIconCol {
    display: flex;
    flex-direction: column;
    width: 5%;
    visibility: hidden;
    margin-left: 0.5vw;
    align-self: center;
}

.clientCard:hover .deleteIcon {
    visibility: visible;
}

.deleteIcon:hover {
    cursor: pointer;
}

.logoGoldenIconContainer {
    display: flex;
    top: 0;
    right: 0;
}

.goldenIcon {}