.products {
    font-family: red-hat-display-bold;
    margin-top: 3.9787798408488064vh;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    height: auto;
    column-gap: 3%;
    margin-left: 10%;
}

.rowWithHeight {
    display: flex;
    flex-direction: row;
    height: 5.305039787798409vh;
}

.rowShowBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 5.319917440660475vh;
}

.btnShowAll {
    font-family: mulish-light;
    font-size: 16px;
    color: #1C8693;
    text-align: center;
    width: 8.854166666666666vw;
    height: 6.23342175066313vh;
    border-radius: 10px;
    border-color: #1C8693;
    border-width: thin;
    background-color: white;
}