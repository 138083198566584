.row {
    display: flex;
    flex-direction: row;
    height: auto;
    width: 100%;
}

.sectionReset {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 2%;
    padding-right: 2%;
}

.empty {
    width: 100%;
    height: 8vw;
    display: flex;
    flex-direction: row;
    margin-bottom: 0 !important;
    align-items: center;
}

.resetRow {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    height: 40vh;
}

.titleRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.title {
    font-size: xx-large;
    color: #03464b;
    font-family: mulish-light;
}

.formRow {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.col {
    display: flex;
    flex-direction: column;
    width: 25%;
}

.form {
    display: flex;
    flex-direction: column;
    width: 50%;
    color: #03464b;
    font-size: large;
}

.labelclass {
    font-size: 0.8635416666666665vw;
    font-family: mulish-light;
    color: #03464b;
}

.errorMessage {
    color: red;
    margin-bottom: 0 !important;
    font-size: 0.7vw;
    font-family: mulish-light;
}

.submitBtnContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 1vw;
}

.btnSubmit {
    background-color: transparent;
    width: auto;
    height: auto;
    border-radius: 0.5vw;
    border: 0.1vw solid #1c8693;
    align-items: center;
    justify-content: center;
}

.txtSubmit {
    color: #1c8693;
    font-size: 1.0364583333333333vw;
    font-family: mulish-light;
    font-weight: bold;
    margin-bottom: 0 !important;
    padding: 0.3vw;
}

.rowWithHeight{
    display: flex;
    flex-direction: row;
    height: 10vh;
}

