.titleRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: auto;
}

.newsContainer {
    display: flex;
    flex-direction: column;
    background-image: url("../../../assets/styles/images/home/news/VESTI\ BOX\ BG.png");
    background-size: cover;
    width: 25vw;
    height: 75.55248618802084vw;
}

.newsContent {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.news {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: auto;
    align-items: center;
}

.arrowsCol {
    display: flex;
    flex-direction: column;
    margin-right: 2vw;
}