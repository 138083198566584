.row{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 3vh;
}

.durationCol{
    display: flex;
    flex-direction: column;
    width: 50%;
}

.routeName{
    font-size: xx-large;
    padding: 5px;
}

.title{
    font-size: xx-large;
    color: #818181;
}

.basicRow{
    display: flex;
    flex-direction: row;
}

.errorMessage{
    color:#D8000C;
    font-family: mulish-light;
    font-size: 1.04375vw;
}