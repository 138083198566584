.filterTitle {
    width: 90%;
}

.filterAndPaginationSection {
    display: flex;
    justify-content: start;
    flex-direction: row;
    width: 100%;
    padding-left: 2.5625vw;
}

.plusLink {
    display: flex;
    flex-direction: column;
    width: 20%;
    font-size: xx-large;
    align-self: center;
}

.plusLinkDesign {
    font-size: xx-large;
    color: #1C8693;
    font-family: mulish-light;
}

.filterCol {
    display: flex;
    justify-content: start;
    flex-direction: column;
    width: 50%;
    margin-left: 2%;
}

.filterSection {
    width: 100%;
    display: flex;
    justify-content: start;
    flex-direction: row;
    background-color: #ECECEC;
    border-radius: 20px;
    height: auto;
}

.filterCategory {
    display: flex;
    flex-direction: column;
    margin-left: 2%;
    width: 22.5%;
}

.categoryRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.marginTop {
    margin-top: 20px;
}

.filterTitlesColor {
    color: #197d89;
    font-family: roboto-bold;
    font-size: 12px;
}

.routeIcon {
    width: 1.6666666666666667vw;
    height: 3.199174406604747vh;
    margin-top: 7%;
    margin-right: 5%;
}

.row {
    display: flex;
    flex-direction: row;
}

.categories {
    font-family: roboto-bold;
    font-size: 14px;
}

.divider {
    width: 0.10416666666666667vw;
    height: 5.469556243550052vh;
    margin-top: 2.063983488132095vh;
}

.filterRate {
    display: flex;
    flex-direction: column;
    width: 40%;
    align-items: center;
}

.rateIcon {
    width: 1.875vw;
    height: 3.0959752321981426vh;
    margin-left: 0.5208333333333334vw;
    margin-top: 7%;
}

.filterClient {
    display: flex;
    flex-direction: column;
    margin-left: 2%;
    width: 20%;
}

.dotsIcon {
    width: 1.5625vw;
    height: 0.8255933952528379vh;
    margin-left: 4.8208333333333334vw;
    margin-top: 10%;
}

.clients {
    font-family: roboto-bold;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    width: 80%;
    align-items: center;
    justify-content: center;
    z-index: 5000 !important;
}

.filterSearch {
    display: flex;
    flex-direction: column;
    width: 10%;
    background-color: #1C8693;
    border-radius: 15px;
    height: 5.668169761273209vh;
    margin-top: 2.0262599469496021vh;
    margin-right: 0.5208333333333334vw;
    margin-left: 0.4vw;
    justify-content: center;
    align-items: center;
}

.filterSearch:hover {
    cursor: pointer;
}

.filterIcon {
    display: flex;
    flex-direction: column;
    background-image: url("../../../../assets/styles/images/ikonicaSearch.png");
    background-size: cover;
    width: 25px;
    height: 25px;
}