.newsCard {
    background-color: #fff;
    margin: 0.75vw;
    border-radius: 1vw;
    width: 23vw;
    height: 7.421875vw;
    display: flex;
    flex-direction: row;
}

.newsCard:hover .iconCol {
    visibility: visible;
}

.newsCardSection1 {
    width: 7.421875vw;
}

.imgNewsContainer {
    display: flex;
    flex-direction: row;
    width: 7.421875vw;
    height: 7.421875vw;
}

.imgNews {
    display: flex;
    width: 7.421875vw;
    height: 7.421875vw;
}

.imgNewsBorderRadius {
    display: flex;
    width: 7.421875vw;
    height: 7.421875vw;
    border-radius: 0.5vw;
}

.newsCardSection2 {
    width: 15.578125vw;
}

.titleRow {
    display: flex;
    flex-direction: row;
    height: 1.5625vw;
}

.newsTitleContainer {
    margin-bottom: 0 !important;
    padding: 0.06510416666666667vw;
    text-align: left;
    display: flex;
    flex-direction: column;
    height: 1.4vw;
    margin-left: 0.2604166666666667vw;
}

.newsTitle {
    color: #1c8693;
    font-family: mulish-light;
    font-size: 0.9114583333333334vw;
    font-weight: bold;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0 !important;
}

.iconCol {
    display: flex;
    flex-direction: column;
    width: 10%;
    visibility: hidden;
}

.deleteIcon:hover {
    cursor: pointer;
}

.newsDescriptionContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 4.394270833333334vw;
    margin-left: 0.2604166666666667vw;
}

.newsDescription {
    font-family: mulish-light !important;
    font-size: 0.78125vw !important;
    color: #696969 !important;
    text-align: inherit;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0 !important;
}

.newsDescription * {
    font-family: mulish-light !important;
    font-size: 0.78125vw !important;
    color: #696969 !important;
}

.detailsBtnContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 1.5625vw;
}

.detailsBtn {
    display: flex;
    flex-direction: row;
    background-image: url("../../../../assets/styles/images/home/news/VESTI\ BG\ DUGME\ DESNO.png");
    background-size: cover;
    width: 1.5625vw;
    height: 1.5625vw;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.detailsArrow {
    display: flex;
    flex-direction: row;
    background-image: url("../../../../assets/styles/images/home/news/STRELICA\ DESNO.png");
    background-size: cover;
    width: 0.3255208333333333vw;
    height: 0.5859375vw;
}