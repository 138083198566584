.row{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.serviceTitle{
    font-size: xx-large;
    color: #818181;
}

.basicRow{
    display: flex;
    flex-direction: row;
}

.errorMessage{
    color:#D8000C;
    font-family: mulish-light;
    font-size: 1.04375vw;
}