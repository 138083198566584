.filterAndPaginationSection {
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    margin-left: 2.5625vw;
}

.plusLink {
    display: flex;
    flex-direction: column;
    width: 20%;
    font-size: xx-large;
    align-self: center;
}

.plusLinkDesign {
    font-size: x-large;
    color: #1C8693;
    font-family: mulish-light;
    text-decoration: none;
}

.filterCol {
    display: flex;
    justify-content: start;
    flex-direction: column;
    width: 60%;
}

.filterSection {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    background-color: #ECECEC;
    border-radius: 20px;
    height: auto;
}

.filterType {
    display: flex;
    flex-direction: column;
    width: 20%;
}

.types {
    font-family: roboto-bold;
    font-size: 14px;
}

.filterCategory {
    display: flex;
    flex-direction: column;
    width: 25%;
}

.filterRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 90%;
    padding-left: 10px;
}

.marginTop {
    margin-top: 2.6525198938992043vh;
}

.filterTitlesColor {
    color: #197d89;
    font-family: roboto-bold;
    font-size: 12px;
}

.filterCategoryIcon {
    width: 1.6666666666666667vw;
    height: 3.199174406604747vh;
    align-self: center;
    margin-left: 1vw;
    margin-top: 0.9vh;
}

.row {
    display: flex;
    flex-direction: row;
}

.categories {
    font-family: roboto-bold;
    font-size: 14px;
}

.filterRate {
    display: flex;
    flex-direction: column;
    width: 25%;
    align-items: center;
}

.rateIcon {
    width: 1.875vw;
    height: 3.0959752321981426vh;
    margin-left: 0.5208333333333334vw;
    margin-top: 7%;
}

.filterName {
    display: flex;
    flex-direction: column;
    width: 28%;
    padding-left: 0.5vw;
}

.filterCategoryRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
}

.dotsIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2.6525198938992043vh;
    margin-left: 1vw;
    width: 1.5625vw;
    height: 0.4166666666666667vw;
    margin-right: 2.5vw;

}

.inputClientName {
    border-radius: 8px;
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: hidden;
    width: 10.020833333333334vw;
    font-family: roboto-regular;
}

.inputClientName:focus {
    outline: none;
}

.filterSearch {
    display: flex;
    flex-direction: column;
    width: 5.668169761273209vh;
    background-color: #1C8693;
    border-radius: 12.5px;
    height: 5.668169761273209vh;
    margin-left: 0.6vw;
    justify-content: center;
    align-items: center;
}

.filterSearch:hover {
    cursor: pointer;
    opacity: 0.8;
}

.filterIcon {
    height: 2.5vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.filterSearchContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.filterTitle {
    display: flex;
    flex-direction: row;
    font-family: mulish-regular;
    color: #1C8693;
    font-size: 18px;
    margin-top: 2.6525198938992043vh;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    margin-bottom: 1vh;
}