.row{
    display: flex;
    flex-direction: row;
}

.numberOfComments{
    font-family: mulish-regular;
    color: #696969;
    margin-left: 8.8125vw;
    font-size:  1.7479876160990713vh;
}

.number{
    font-family: mulish-extrabold;
    font-size:  1.5479876160990713vh;
    color: #696969;
    margin-left: 0.5vw;
    margin-top: 0.25vh;
}

.newCommentContainer{
    display: flex;
    flex-direction: column;
    width: 55%;
    margin-left: 5em;
    margin-right: 2em;
    margin-top: 3em;
}

.arrowsRow{
    display: flex;
    flex-direction: row;
    width: 75%;
    justify-content: flex-end;
    margin-top: 3vh;
}

.rowWithHeight{
    display: flex;
    flex-direction: row;
    height: 5.159958720330238vh;
}