.upload {
    margin-top: 3%;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    justify-content: center;
}

.buttonTitle{
    font-size: xx-large;
    color: gray;
    border: 0px;
    border-radius: 10px;
}

.errorMessage{
    color:#D8000C;
    font-family: mulish-light;
    font-size: 1.04375vw;
}

.sortableContainer{
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
}

.row1{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
}

.sortable{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
}

.imgContainer{
    display: flex;
    flex-direction: row;
    width: 250px;
    height: 250px;      
    position: relative;
}

.img{
    width: 100%;
    height: 235px;     
    padding-top: 3%;   
    padding-left: 10%;    
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
}

.deleteIcon{
    position: absolute;
    top: 3%;
    left: 90%;
    z-index: 9;
}

.deleteIcon:hover{
    cursor: pointer;
}