.rowArrows {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 1vw;
}

.colArrows {
    display: flex;
    flex-direction: column;
    margin-right: 10vw;
}

.sectionClientsContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 5vw;
    margin-right: 5vw;
}

.clientsContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1vw;
}

.clients {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: auto;
    justify-content: center;
    column-gap: 1.5vw;
}