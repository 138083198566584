.sectionCountryNews{
    display: flex;
    flex-direction: row;
    height: auto;
    width: 100%;
}

.countrySection{
    display: flex;
    flex-direction: column;
    height: auto;
    width: 75%;
    padding-left: 2%;
    padding-right: 2%;
}

.titleRow{
    display: flex;
    flex-direction: row;
    width: 50%;
    align-self: center;
}

.sectionNews{
    display: flex;
    flex-direction: column;
}

.formRow{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 5vh;
}

.form{
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-left: 25%;
    color: #818181;
    font-family: mulish-light;
    font-size: 1.64375vw;
}

.errorMessage{
    color:#D8000C;
    font-family: mulish-light;
    font-size: 1.04375vw;
}

.btnSave{
    font-family: mulish-light;
    font-size: 16px;
    color: #1C8693;
    text-align: center;
    width: 5.854166666666666vw;
    height: 4.23342175066313vh;
    border-radius: 10px;
    border-color: #1C8693;
    border-width: thin;
    background-color: white;
    width: 25%;
    align-self: center;
}

.btnSave:hover{
    opacity: 0.7;
    cursor: pointer;
}