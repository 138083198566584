.searchBoxContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 10%;
    margin-top: 5%;
}

.addNewsBtn {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-self: center;
    width: 4.643962848297214vh;
}

.goPlus:hover {
    cursor: pointer;
}

.inputSearchBoxContainer {
    display: flex;
    flex-direction: row;
    width: 80%;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.inputSearchBox {
    display: flex;
    flex-direction: row;
    background-image: url("../../../../assets/styles/images/home/news/VESTI\ SEARCH\ BOX.png");
    background-size: cover;
    width: 16.731770833333332vw;
    height: 3.1901041666666665vw;
    border: 0px;
    border-radius: 10px;
    font-size: x-large;
    font-family: mulish-light;
}

.inputSearchBox:focus {
    outline: none;
  }

.searchBtnContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 15%;
}

.searchBtn {
    display: flex;
    flex-direction: row;
    background-image: url("../../../../assets/styles/images/home/news/VESTI\ DUGME\ SEARCH\ BG.png");
    background-size: cover;
    width: 3.1901041666666665vw;
    height: 3.1901041666666665vw;
    align-items: center;
    justify-content: center;
}

.searchBtn:hover{
    cursor: pointer;
    opacity: 0.8;
}

.searchIcon {
    display: flex;
    flex-direction: row;
    background-image: url("../../../../assets/styles/images/home/news/IKONICA\ SEARCH.png");
    background-size: cover;
    width: 1.6276041666666667vw;
    height: 1.6276041666666667vw;
}