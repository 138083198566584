.sectionClientNews{
    display: flex;
    flex-direction: row;
    height: auto;
    width: 100%;
}

.clientSection{
    display: flex;
    flex-direction: column;
    height: auto;
    width: 75%;
    padding-left: 2%;
    padding-right: 2%;
}

.saveRow{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.btnSave{
    font-family: mulish-light;
    font-size: 16px;
    color: #1C8693;
    text-align: center;
    width: 5.854166666666666vw;
    height: 4.23342175066313vh;
    border-radius: 10px;
    border-color: #1C8693;
    border-width: thin;
    background-color: white;
}

.basicRow{
    display: flex;
    flex-direction: row;
}

.sectionNews{
    display: flex;
    flex-direction: column;
}

.gallery{
    margin-left: 10%;
    width: 80%;
}