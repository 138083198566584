.row{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.title{
    font-size: xx-large;
    color: #818181;
}

.countryIcon{
    width: 4.239938080495356vh;
    height: 4.239938080495356vh;
}

.errorMessage{
    color:#D8000C;
    font-family: mulish-light;
    font-size: 1.04375vw;
    margin-left: 6.239938080495356vh;
}