.findCoordsRow{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.btnFindCoord{
    font-family: mulish-light;
    font-size: 16px;
    color: #1C8693;
    text-align: center;
    width: auto;
    height: 4.23342175066313vh;
    border-radius: 10px;
    border-color: #1C8693;
    border-width: thin;
    background-color: white;
}

.mapSection{
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    height: 55.59958720330237vh;
    z-index: 1;
    margin-top: 2vh;
}

.map{
    width: 100%;
    height: 100%;            
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
}

.dropdown{
    border: 0px;
    position: absolute;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 9;
    width: 40%;
    top: 0.5%;
    left: 59.8%;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    align-content: center;
    text-align: center;
}

.latLngInput{
    position: absolute;
    height: 10%;
    top: 90%;
    left: 80%;
    z-index: 9;
}

.rowEnd{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.errorMessage{
    color:#D8000C;
    font-family: mulish-light;
    font-size: 1.04375vw;
    margin-left: 6.239938080495356vh;
}